import React, { useEffect, useState, useCallback } from "react";
import Card from "../Card/Card";
import io from "../../actions/socket";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { rootApi } from "../../axiosConfig";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useBreadcrumbContext } from "../../context/breadcrumbContext";
import { useHistory } from "react-router-dom";

const DealerCard = ({
  dealer,
  editHandlerEvent,
  deleteHandlerEvent,
  activateLinkHandlerEvent,
  to,
}) => {
  const { t } = useTranslation();
  const [countAlert, setCountAlert] = useState(false);
  const [hpfAlert, setHpfAlert] = useState(false)
  const [chillerAlert, setChillerAlert] = useState(false)
  const { setBreadcrumbDealer } = useBreadcrumbContext();
  const history = useHistory();

  const mapAlert = useCallback(async () => {
	if (dealer?.dairies && dealer.dairies.length !== 0) {
		// Now process API calls and exit after finding the first alert
		for (const item of dealer.dairies) {
			try {
				const alertResponse = await rootApi.get(`alerthistories?where={"and":[{"alert":null},{"isResolved":false},{"dairy":"${item.id}"},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&limit=10`);
				if (alertResponse.data.length > 0) {
					setCountAlert(true);
					return; // return if HPF alert is found
				}

				const chillerAlertResponse = await rootApi.get(`chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${item.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_CHILLER"}}]}&limit=10`);
				if (chillerAlertResponse.data.length > 0) {
					setCountAlert(true);
					return; // return if Chiller alert is found
				}
			
				const turckAlertResponse = await rootApi.get(`turckalerthistories?where={"and":[{"turckAlert":null},{"isResolved":false},{"dairy":"${item.id}"},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]}&limit=10`);
				if (turckAlertResponse.data.length > 0) {
					setCountAlert(true);
					return; // return if Turck alert is found
				}
			} catch (err) {
				toast.dark(<Toaster icon="error" message={err?.response?.message ?? t("alert_history_error")} />);
			}
		}

		dealer.dairies.forEach((item) => {
			/** live alert using socket connection  */
			io.socket.on(`alerthistory:manual:${item.id}`, (data1) => {
				if (!data1["isResolved"]) {
					setCountAlert(true);
					setHpfAlert(true);
				} else {
					rootApi
						.get(`alerthistories?where={"and":[{"alert":null},{"dairy":"${item.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&limit=10`)
						.then((response) => {
							const isHpfAlert = response.data.length ? true : false;
							setHpfAlert(isHpfAlert);
							setCountAlert(isHpfAlert || chillerAlert ? true : false);
						})
						.catch((err) => {
							toast.dark(<Toaster icon="error" message={err?.response?.message ?? t("alert_history_error")} />);
						});
				}
			});

			io.socket.on(`chiller-alerthistory:manual:${item.id}`, (data1) => {
				if (!data1["isResolved"]) {
					setCountAlert(true);
					setChillerAlert(true);
				} else {
					rootApi
						.get(`chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${item.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_CHILLER"}}]}&limit=10`)
						.then((response) => {
							const isChillerAlert = response.data.length ? true : false;
                  			setHpfAlert(isChillerAlert);
							setCountAlert(isChillerAlert || hpfAlert ? true : false);
						})
						.catch((err) => {
							toast.dark(<Toaster icon="error" message={err?.response?.message ?? t("alert_history_error")} />);
						});
				}
			});

			io.socket.on(`turck-alerthistory:manual:${item.id}`, (data1) => {
				if (!data1["isResolved"]) {
					setCountAlert(true);
					setHpfAlert(true);
				} else {
					rootApi
						.get(`turckalerthistories?where={"and":[{"turckAlert":null},{"dairy":"${item.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]}&limit=10`)
						.then((response) => {
							const isTurckAlert = response.data.length ? true : false;
							setHpfAlert(isTurckAlert);
							setCountAlert(isTurckAlert);
						})
						.catch((err) => {
							toast.dark(<Toaster icon="error" message={err?.response?.message ?? t("alert_history_error")} />);
						});
				}
			});
		});
	}
}, [dealer, toast]);

useEffect(() => {
	mapAlert();
}, [mapAlert]);

  
  /** Display dairy count in dealer card */
  const dairies = dealer.dairies
    ? dealer.dairies.length === 1
      ? dealer.dairies.length + " " + t('site')
      : dealer.dairies.length === 0
      ? "--"
      : dealer.dairies.length + " " + t('sites')
    : "";
  return (
    <Card
      key={`dealer` + dealer.id}
      title={dealer.name}
      subtitle={dairies}
      isAlert={countAlert}
      rightIcon={false}
      onClick={() => {
        if (to) {
          history.push(to);
          setBreadcrumbDealer(dealer.name);
        } else {
          history.push(
            `/regions/${dealer.region}/dealers/${dealer.id}/dairies`
          );
          setBreadcrumbDealer(dealer.name);
        }
      }}
      notRegister={
        dealer.isActive === false && (
          <div className="text-capitalize">{t("not_register")}</div>
        )
      }
      deleteHandler={() => {
        deleteHandlerEvent(dealer.id);
      }}
      editHandler={() => {
        editHandlerEvent(dealer.id);
      }}
      activateLinkHandler={() => {
        activateLinkHandlerEvent(dealer.email);
      }}
    />
  );
};

DealerCard.propTypes = {
  dealer: PropTypes.any,
};

export default DealerCard;
