import React, { memo, useCallback, useEffect, useState } from "react";
import Card from "../Card/Card";
import io from "../../actions/socket";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { rootApi } from "../../axiosConfig";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useBreadcrumbContext } from "../../context/breadcrumbContext";
import { useHistory } from "react-router-dom";
import { getUser } from "../../actions/actions";

const DairyCard = memo(
  ({ dairy, users, editHandlerEvent, deleteHandlerEvent }) => {
    const { t } = useTranslation();
    const userRole = getUser();
    const [countAlert, setCountAlert] = useState(false);
    const { setBreadcrumbDairyFarm } = useBreadcrumbContext();
    const [hpfAlert, setHpfAlert] = useState(false)
    const [chillerAlert, setChillerAlert] = useState(false)
    const history = useHistory();
    
    const mapAlerts = useCallback(async () => {
			/** live alert using socket connection  */
			io.socket.on(`alerthistory:manual:${dairy.id}`, (data1) => {
				if (!data1["isResolved"]) {
					setCountAlert(true);
					setHpfAlert(true);
				} else {
					rootApi
                .get(
                  `alerthistories?where={"and":[{"alert":null},{"dairy":"${dairy.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&limit=10`
                )
						.then((response) => {
							const isHpfAlert = response.data.length ? true : false;
							setHpfAlert(isHpfAlert);
							setCountAlert(isHpfAlert || chillerAlert ? true : false);
						})
						.catch((err) => {
                  toast.dark(
                    <Toaster
                      icon="error"
                      message={err?.response?.message ?? t("alert_history_error")}
                    />
                  );
						});
				}
			});

			/** live alert using socket connection  */
			io.socket.on(`chiller-alerthistory:manual:${dairy.id}`, (data1) => {
				if (!data1["isResolved"]) {
					setCountAlert(true);
					setChillerAlert(true);
				} else {
					rootApi
                .get(
                  `chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairy.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_CHILLER"}}]}&limit=10`
                )
						.then((response) => {
							const isChillerAlert = response.data.length ? true : false;
							setHpfAlert(isChillerAlert);
							setCountAlert(isChillerAlert || hpfAlert ? true : false);
						})
						.catch((err) => {
                  toast.dark(
                    <Toaster
                      icon="error"
                      message={err?.response?.message ?? t("alert_history_error")}
                    />
                  );
						});
				}
			});

			/** live alert using socket connection  */
			io.socket.on(`turck-alerthistory:manual:${dairy.id}`, (data1) => {
				if (!data1["isResolved"]) {
					setCountAlert(true);
					setHpfAlert(true);
				} else {
					rootApi
                .get(
                  `turckalerthistories?where={"and":[{"turckAlert":null},{"dairy":"${dairy.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]}&limit=10`
                )
						.then((response) => {
							const isTurckAlert = response.data.length ? true : false;
							setHpfAlert(isTurckAlert);
							setCountAlert(isTurckAlert);
						})
						.catch((err) => {
                  toast.dark(
                    <Toaster
                      icon="error"
                      message={err?.response?.message ?? t("alert_history_error")}
                    />
                  );
						});
				}
			});

			// Fetch alerts for HPF from alertHistory
			try {
				const alertResponse = await rootApi.get(`alerthistories?where={"and":[{"alert":null},{"isResolved":false},{"dairy":"${dairy.id}"},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&limit=10`);
				if (alertResponse.data.length > 0) {
					setCountAlert(true);
					return; // return if HPF alert is found
				}
			
			// Fetch alerts for CHILLER from chillerAlertHistory
				const chillerAlertResponse = await rootApi.get(`chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairy.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS_CHILLER"}}]}&limit=10`);
				if (chillerAlertResponse.data.length > 0) {
					setCountAlert(true);
					return; // return if Chiller alert is found
				}
	
			// Fetch alerts for TURCK from alertHistory
				const turckAlertResponse = await rootApi.get(`turckalerthistories?where={"and":[{"turckAlert":null},{"isResolved":false},{"dairy":"${dairy.id}"},{"configKey":{"!=":"MANUAL_ALERTS_TURCK"}}]}&limit=10`);
				if (turckAlertResponse.data.length > 0) {
					setCountAlert(true);
					return; // return if Turck alert is found
				}
			} catch (err) {
				toast.dark(<Toaster icon="error" message={err?.response?.message ?? t("alert_history_error")} />);
			}
		}, []);
    useEffect(() => {
      mapAlerts();
    }, [dairy]);

    /** Display tank count in dairy card */
    const totalCount = dairy?.tanks?.length + dairy?.chillers?.length
    const tankCount = totalCount
      ? totalCount === 1
        ? totalCount + " " + t('device')
        : totalCount + " " + t('devices')
      : totalCount === 0
        ? "--"
        : ""

    return (
      <Card
        key={"dairyCard" + dairy.id}
        title={dairy.name}
        subtitle={dairy.city + ", " + dairy.state}
        rightIcon={true}
        onClick={() => {
          let url = `/regions/${dairy?.createdBy?.region}/dealers/${dairy.createdBy.id}/dairies/${dairy.id}/tank`;
          if (
            userRole.role === "Technician" ||
            userRole.role === "DairyOperator" ||
            userRole.role === "DairyAdmin" ||
            userRole.role === "Sanitarian" ||
            userRole.role === "MilkHauler"
          ) {
            url = `/regions/dealers/${userRole.role === "Technician" ? users.createdBy : users.id}/dairies/${dairy.id}/tank`;
          }
          if (!dairy?.createdBy?.region) {
            url = `/regions/dealers/${users.id}/dairies/${dairy.id}/tank`;
          }
          history.push(url);
          setBreadcrumbDairyFarm(dairy.name);
        }}
        deleteHandler={() => {
          deleteHandlerEvent(dairy.id);
        }}
        editHandler={() => {
          editHandlerEvent(dairy.id);
        }}
        item={dairy}
        isMonitoring={
          userRole.role === "DairyOperator" ||
            userRole.role === "Sanitarian" ||
            userRole.role === "MilkHauler" ||
            userRole.role === "DairyAdmin"
            ? ""
            : dairy.monitors.findIndex((el) => el.id === users.id) > -1
              ? 1
              : 0
        }
        isAlert={countAlert}
      >
        {tankCount}
      </Card>
    );
  }
);

DairyCard.propTypes = {
  dairy: PropTypes.any,
  users: PropTypes.any,
};

export default DairyCard;
